




















import { Component, Vue } from 'vue-property-decorator'
const VueGallery= require('vue-gallery')

@Component({
  components: {
	'gallery': VueGallery
  }}) 
export default class Gallery extends Vue {
    private images :Array<string> = [];
    private index :null|number = null
    public changeComponent() {
	  this.$router.replace({name:"Home"})
    }
    public makeSrcs() {
        for(let i=35;i>0;i--) {
            let img=require(`../assets/drapak${i}.jpg`)
            this.images.push(img);
        }
    }
    created() {
        this.makeSrcs();
        console.log(this.images)
    }
}
