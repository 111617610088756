

























const Glide= require('vue-glide-js')
const GlideSlide= require('vue-glide-js')
import { Component, Vue } from 'vue-property-decorator'

interface Options {
  gap :Number,
  type: string,
  autoplay: number,
  rewind: boolean,
  bound: boolean,
  perView?: number,
  breakpoints?: {},
}

@Component({
  components: {
	[Glide.name]: Glide,
	[GlideSlide.name]: GlideSlide,
  }}) 

export default class MainView extends Vue {
  private srcArr: Array<string> = ["drapak17.jpg","drapak31.jpg","drapak22.jpg", "drapak24.jpg", "drapak26.jpg", "drapak28.jpg", "drapak15.jpg", "drapak18.jpg" ]
  private options: Options = {
    gap: 0,
    type: 'carousel',
    autoplay: 4000,
    rewind: true,
    bound: true,
    breakpoints: {
      1184: {
      perView: 1,
      }, 
    }
  }
  public changeToGallery() {
	  this.$router.replace({name:"Gallery"})
  }
}
